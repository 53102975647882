import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { Title } from '@kota/ui';

const columnHelper = createColumnHelper();

type Props = {
  title?: string | JSX.Element;
  columns: {
    accessor: string;
    header: string;
    cell?: (info: any) => JSX.Element;
    align?: React.ThHTMLAttributes<HTMLTableColElement>['align'];
  }[];
  data: any[];
};

export default function Table({ title, actions, data, columns }: Props): JSX.Element {
  const cols = columns.map((column) => {
    return columnHelper.accessor(column.accessor, {
      header: () => column.header,
      footer: (info) => info.column.id,
      ...(column.cell && { cell: column.cell }),
      meta: {
        align: column.align || 'left',
      },
    });
  });

  const table = useReactTable({
    data,
    columns: cols as typeof data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className='overflow-hidden rounded-xl border'>
      <div className='flex items-center justify-between px-4 py-3'>
        {title && (
          <div>
            {typeof title === 'string' ? (
              <Title size='18' weight='semibold'>
                {title}
              </Title>
            ) : (
              title
            )}
          </div>
        )}

        {actions && <div>{actions}</div>}
      </div>

      <table className='w-full'>
        <thead className='bg-kota-neutral-5'>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className='text-kota-text-20 p-3 text-xs font-medium first:pl-6 last:pr-6'
                  align={(header.column.columnDef.meta as any)?.align}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className='border-b last:border-none'>
              {row.getVisibleCells().map((cell) => (
                <td
                  className='p-3 text-sm first:pl-6 last:pr-6'
                  key={cell.id}
                  align={(cell.column.columnDef.meta as any)?.align}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
